/* index.css or App.css */
body {
  font-family: 'Gordita', sans-serif;
  /* background-color: #fbf4ee;  */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Other global styles can go here */
