
@import url('https://fonts.googleapis.com/css2?family=Gorditas:wght@400;700&display=swap');

/* @font-face {
    font-family: 'gordita';
    src: url('/public/gordita/gordita_black-webfront.woff') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
   */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'gordita';
  src: url('../static/font/gordita_regular-webfont.woff') format('truetype');
}

@layer base {
  html {
      @apply scroll-smooth;
  }
  body {
      font-family: 'gordita', sans-serif;
  }
}
